@import 'bootstrap/dist/css/bootstrap.min.css';
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    font-family: "Poppins", sans-serif;
    padding: 0;
    margin: 0;
}

.text-deco-none {
    text-decoration: none;
}

.text-pad-start {
    padding-inline: 10px;
    padding-block: 5px;
}

.text-col {
    color: black;
    /* color: #1a191a; */
}

.text-col.active {
    text-decoration: underline;
    text-underline-offset: 0.4rem;
    border-radius: 10px;
    color: rgb(0, 131, 246);
    transition: all ease 0.5s;
    /* width={100} height={500}  */
}

.kapil {
    transform: scaleX(-1);
    width: 280px;
    height: 300px;
    
}

.corousel-mb{

    img{
        width: 100%;
        height: 600px;
    }
}

.kapil-box{
    display: flex;
    flex-wrap: wrap;
    padding-inline: 10%;
}


@media screen and (max-width: 576px) {

    .kapil-box{
        display: block;
        flex-wrap: wrap;
        padding-inline: 10%;

        img{
            width: 100%;
            height: 100%;
        }
    }

    .corousel-mb{
       
        img{
            width: 100%;
            height: 200px;
        }
    }


    .kapil-img{

        img{
            width: 80%;
            height: 80%;
        }
    }

}